import { FC } from 'react';
import { Container, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { setPage } from '../../stores/propertiesSlice';
import { useAppDispatch, useAppSelector } from '../../stores/store';

const Paginador: FC<{
  actualPage: number;
  pages: number;
  total: number;
}> = ({ actualPage, pages, total }) => {
  const dispatch = useAppDispatch();
  const itemPerPage = useAppSelector<number>((state: any) => state.properties.itemPerPage);

  const lastPage = Math.round(total / itemPerPage);
  const halfPage = Math.trunc(pages / 2);

  const itemsFirstPage = actualPage <= halfPage ? 1 : actualPage - halfPage;
  const itemsLastPage = actualPage > halfPage ? lastPage : actualPage + halfPage;
  // Crear un array de elementos JSX basado en el número de páginas.
  const paginationItemsIA = Array.from(
    { length: pages },
    (_, index) =>
      itemsFirstPage + index <= lastPage && (
        <PaginationItem key={index} disabled={actualPage === itemsFirstPage + index}>
          <PaginationLink className="font-azul" onClick={() => setNewPage(itemsFirstPage + index)}>
            {itemsFirstPage + index}
          </PaginationLink>
        </PaginationItem>
      )
  );

  const setNewPage = (page: number) => {
    //TODO: Para activar la busqueda del home.tsx
    dispatch(setPage(page));
    const scrollToElement = () => {
      const element = document.getElementById('propiedadesListado');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    scrollToElement();
  };

  return total > itemPerPage ? (
    <>
      {
        <Container className="d-flex justify-content-center">
          <Pagination>
            <PaginationItem disabled={actualPage <= 1}>
              <PaginationLink
                first
                className="font-azul"
                // href="#"
                onClick={() => setNewPage(1)}
              />
            </PaginationItem>
            <PaginationItem disabled={actualPage <= 1}>
              <PaginationLink
                className="font-azul"
                // href="#"
                previous
                onClick={() => setNewPage(actualPage - 1)}
              />
            </PaginationItem>
            {paginationItemsIA}
            <PaginationItem disabled={actualPage === lastPage}>
              <PaginationLink
                // href="#"
                className="font-azul"
                next
                onClick={() => setNewPage(actualPage + 1)}
              />
            </PaginationItem>

            <PaginationItem disabled={actualPage === lastPage}>
              <PaginationLink
                last
                className="font-azul"
                // href="#"
                onClick={() => setNewPage(lastPage)}
              />
            </PaginationItem>
          </Pagination>
        </Container>
      }
    </>
  ) : (
    <></>
  );
};
export default Paginador;
