// import dotenv from 'dotenv';

// dotenv.config();

export const config = {
  TITULO: process.env.REACT_APP_TITULO || '',
  MESSAGES_HUB_API_URL: process.env.REACT_APP_MESSAGES_HUB_API_URL || '',
  API_URL: process.env.REACT_APP_API_URL || '',
  URL: process.env.REACT_APP_URL || ''
};
