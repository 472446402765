export default interface FormData {
  name: string;
  email: string;
  phone: string;
  operation: string;
  propertyType: string;
  location: string;
  numberOfRooms: string;
  estimatedCost: string;
  message: string;
}

export const formDataEmpty: FormData = {
  name: '',
  email: '',
  phone: '',
  operation: '',
  propertyType: '',
  location: '',
  numberOfRooms: '',
  estimatedCost: '',
  message: ''
};
