import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import agentsSlice from './agentsSlice';
import counterReducer from './counterSlice';
import citiesSlice from './locationsSlice';
import propertiesReducer from './propertiesSlice';
import sharedSlice from './sharedSlice';
import typesSlice from './typesSlice';

const store = configureStore({
  reducer: {
    counter: counterReducer,
    properties: propertiesReducer,
    agents: agentsSlice,
    cities: citiesSlice,
    types: typesSlice,
    shared: sharedSlice
  }
});

// Tipos de ayuda para usar en componentes
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Hooks personalizados para usar en tus componentes
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
