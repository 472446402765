import { IconButtonProps } from '@mui/material/IconButton';
import { FC, useEffect } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Container } from 'reactstrap';
import Carousel from 'react-multi-carousel';

import Property from '../models/Property';
import { getFeatures } from '../services/properties.services';
import { setFeatures } from '../stores/propertiesSlice';
import { useAppDispatch, useAppSelector } from '../stores/store';
import Propiedad from './subComponents/propiedad.component';
import Title from './subComponents/title.component';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const Destacadas: FC = () => {
  const dispatch = useAppDispatch();
  const featuresList = useAppSelector<Property[]>(state => state.properties.features);

  useEffect(() => {
    const features = async () => {
      try {
        const features = await getFeatures();
        dispatch(setFeatures(features));
      } catch (error) {
        console.error(error);
      }
    };
    if (featuresList.length == 0) {
      features();
    }
  }, []);

  return featuresList.length > 0 ? (
    <section id="destacadas" className="ftco-section bg-white" style={{ padding: '3em 0' }}>
      <Title title="Propiedades destacadas" />
      <Container>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={true}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 2,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 1,
              partialVisibilityGutter: 30
            }
          }}
          rewind={true}
          rewindWithAnimation={true}
          // rtl
          shouldResetAutoplay
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {featuresList.length > 0 && featuresList.map((p, k) => <Propiedad p={p} key={k} isFeature={true} />)}
        </Carousel>
      </Container>
    </section>
  ) : (
    <></>
  );
};
export default Destacadas;
