import { FC } from 'react';
import WhatsApp from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

const Contact: FC<{ phone?: string; textWhastapp?: string; textEmail?: string }> = ({
  phone,
  textWhastapp,
  textEmail
}) => {
  return (
    <div className="d-flex align-items-center justify-content-end" style={{ padding: '0.5em 0' }}>
      {textWhastapp && (
        <>
          <IconButton
            className="background-whastapp"
            onClick={() => {
              window.open(`${textWhastapp}`, '_blank');
            }}
          >
            <WhatsApp />
          </IconButton>
          <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
            <IconButton>
              <CallIcon />
            </IconButton>
          </a>
        </>
      )}
      {textEmail && (
        <a href={textEmail} target="_blank" rel="noreferrer">
          <IconButton>
            <MailIcon />
          </IconButton>
        </a>
      )}
    </div>
  );
};
export default Contact;
