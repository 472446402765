import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToastSlice {
  message: string;
  type: 'SUCCESS' | 'ERROR' | 'WARN' | 'INFO' | 'DEFAULT';
}
interface SharedInfo {
  loading: boolean;
  openToast: boolean;
  toast: ToastSlice;
}

const initialState: SharedInfo = {
  loading: false,
  openToast: false,
  toast: {
    message: '',
    type: 'DEFAULT'
  }
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setToast: (state, action: PayloadAction<ToastSlice>) => {
      state.toast = action.payload;
      state.openToast = true;
    },
    closeToast: state => {
      state.openToast = false;
    }
  }
});

// Exporta las acciones para usarlas en los componentes
export const { setLoading, setToast, closeToast } = sharedSlice.actions;

// Exporta el reducer para usarlo en el store
export default sharedSlice.reducer;
