import { useState } from 'react';

import { FiltroSlice } from '../stores/propertiesSlice';
import FormSearchProperty from '../models/FormSearchProperty';

export const useFilter = () => {
  const [form, setForm] = useState<FormSearchProperty>({ operation: 1, typesProp: [], cities: [] });
  const buildFilter = (): FiltroSlice => {
    const filtroSlice: FiltroSlice = {
      operacion: 'TODOS',
      localidad: undefined,
      ciudad: undefined,
      typesProp: undefined
    };
    if (form.cities.length > 0) {
      filtroSlice.localidad = form.cities
        .map(i => {
          return i.locality;
        })
        .join(', ');
    }
    switch (form.operation) {
      case 2:
        filtroSlice.operacion = 'VENTA';
        break;
      case 3:
        filtroSlice.operacion = 'ALQUILER';
        break;
      default:
        filtroSlice.operacion = 'TODOS';
        break;
    }
    if (form.typesProp.length > 0) {
      filtroSlice.typesProp = form.typesProp.map(i => i).join(',');
    }
    return filtroSlice;
  };
  return {
    // Variables
    form,
    // Funciones
    buildFilter,
    setForm
  };
};
