import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import CheckIcon from '@mui/icons-material/Check';
import { Alert } from '@mui/material';
import styled from 'styled-components';

// import { getCitiesSimple, getProperties, getTypes } from '../services/properties.services';
import { CitySimple } from '../models/Location';
import Property from '../models/Property';
import { getCitiesSimple, getProperties, getTypes } from '../services/properties.services';
import { setCities } from '../stores/locationsSlice';
import { FiltroSlice, resetProperties, setFiltro, setPage, setProps, setTotalItems } from '../stores/propertiesSlice';
import { useAppDispatch, useAppSelector } from '../stores/store';
import { setTypes } from '../stores/typesSlice';
import { split } from '../tools/share';
import { useFilter } from '../useHooks/filter.hooks';
import Buscador from './subComponents/buscador.component';
import Paginador from './subComponents/paginador.component';
import Propiedad from './subComponents/propiedad.component';
import { setLoading } from '../stores/sharedSlice';
import Title from './subComponents/title.component';

const Propiedades: FC<{ pages: number }> = ({ pages }) => {
  const AlertSyled = styled(Alert)`
    margin: 15px;
    background-color: #003da5 !important;
  `;

  const dispatch = useAppDispatch();
  const filtro = useAppSelector<FiltroSlice>(state => state.properties.filtro);
  const citiesList = useAppSelector<CitySimple[]>(state => state.cities.data);
  const typesList = useAppSelector<string[]>(state => state.types.data);
  const propsList = useAppSelector<Property[]>(state => state.properties.data);

  const page = useAppSelector<number>(state => state.properties.page);
  const itemPerPage = useAppSelector<number>(state => state.properties.itemPerPage);
  const totalItems = useAppSelector<number>(state => state.properties.totalItems);

  const { setForm, form, buildFilter } = useFilter();

  const [preInit, setPreInit] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [modeGA, setModeGA] = useState<boolean>(false);

  const { descripcion, localidad, operacion } = useParams();

  const [initPage, setInitPage] = useState<boolean>(true); // Esto solo se usa para que cuando cargue la pagina no muestre el loading, y si cuando hace una busqueda

  useEffect(() => {
    const cities = async () => {
      try {
        const citiesList = await getCitiesSimple();
        dispatch(setCities(citiesList));
      } catch (error) {
        console.error(error);
      }
    };
    const types = async () => {
      try {
        const typesList = await getTypes();
        dispatch(setTypes(typesList));
      } catch (error) {
        console.error(error);
      }
    };

    cities();
    types();
  }, []);

  useEffect(() => {
    if (citiesList.length > 0 && typesList.length > 0) {
      loadFilterFromUrl();
      setPreInit(true);
    }
  }, [citiesList, typesList]);

  const loadFilterFromUrl = () => {
    //HACK: Se usa por si viene filtro por url para actualizar el filtro del
    if (descripcion) {
      const descripcionSplit = split(descripcion);
      const descripcionFilterURL = typesList.filter(el =>
        descripcionSplit.some(l => el.toLowerCase().includes(l.toLowerCase()))
      );

      setForm(prevState => ({
        ...prevState,
        typesProp: descripcionFilterURL
      }));
      setModeGA(true);
    }
    switch (operacion?.toLowerCase()) {
      case 'venta':
        setForm(prevState => ({
          ...prevState,
          operation: 2
        }));
        break;
      case 'alquiler':
        setForm(prevState => ({
          ...prevState,
          operation: 3
        }));
        break;
      default:
        setForm(prevState => ({
          ...prevState,
          operation: 1
        }));
        break;
    }

    if (localidad) {
      const localidadesSplit = split(localidad);
      const citiesFilterURL = citiesList.filter(el => localidadesSplit.some(l => el.locality.includes(l)));
      setForm(prevState => ({
        ...prevState,
        cities: citiesFilterURL
      }));
    }
  };

  useEffect(() => {
    if (preInit) {
      dispatch(setFiltro(buildFilter()));
      setInit(true);
    }
  }, [preInit]);

  const propiedades = async () => {
    try {
      initPage ? setInitPage(false) : dispatch(setLoading(true));
      const propiedades = await getProperties({ page, limit: itemPerPage, order: '', sort: '' }, filtro, modeGA);
      dispatch(setProps(propiedades.items));
      dispatch(setTotalItems(propiedades.pagination.total));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (init) {
      if (page === 0) {
        //HACK: En caso que cambie el filtro y siga en la pagina 1, fuerzo pagina 0 para que recargue
        dispatch(setPage(1));
      } else {
        propiedades();
      }
    }
  }, [page, init]);

  const submit = () => {
    dispatch(setFiltro(buildFilter()));
    dispatch(resetProperties());
  };

  return (
    <section id="propiedades" className="ftco-section bg-light" style={{ padding: '3em 0' }}>
      <Title title="Nuestras propiedades" />
      <Container maxWidth="sm" style={{ padding: '1em' }}>
        <Buscador setForm={setForm} form={form} onSubmit={submit} />
      </Container>
      <Paginador actualPage={page} pages={pages} total={totalItems} />
      <div className="container-fluid">
        <Alert variant="outlined" severity="info" style={{ margin: '15px' }}>
          Se han encontrado <b>{totalItems} </b>
          {totalItems > 1 ? 'resultados' : 'resultado'}
        </Alert>
        <Row>{propsList.length > 0 && propsList.map((p, k) => <Propiedad p={p} key={k} isFeature={false} />)}</Row>
        {totalItems > 0 && (
          <Alert variant="outlined" severity="info" style={{ margin: '15px' }}>
            Se han encontrado <b>{totalItems} </b>
            {totalItems > 1 ? 'resultados' : 'resultado'}
          </Alert>
        )}
      </div>
      <Paginador actualPage={page} pages={pages} total={totalItems} />
    </section>
  );
};

export default Propiedades;
