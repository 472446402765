import React, { FC, useEffect } from 'react';
import CountUp, { useCountUp } from 'react-countup';
import { Container } from 'reactstrap';

import { getTotales } from '../services/properties.services';
import { setTotals } from '../stores/propertiesSlice';
import { useAppDispatch, useAppSelector } from '../stores/store';
const Datos: FC<{ happies: number }> = ({ happies }) => {
  const dispatch = useAppDispatch();
  const totales = useAppSelector<{
    sales: number;
    rentals: number;
    temporary: number;
  }>(state => state.properties.totals);

  useEffect(() => {
    const totals = async () => {
      try {
        dispatch(setTotals(await getTotales()));
      } catch (error) {
        console.error(error);
      }
    };
    totals();
  }, []);

  const durantionTotal = 24;

  const countUpRef = React.useRef(null);
  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: 1234567,
    delay: 1000,
    duration: 5,
    onReset: () => console.log('Reset'),
    onUpdate: () => console.log('Updated!'),
    onPauseResume: () => console.log('Paused or resumed!'),
    onStart: ({ pauseResume }) => console.log(pauseResume),
    onEnd: ({ pauseResume }) => console.log(pauseResume)
  });

  return (
    <section
      className="ftco-section ftco-counter img"
      id="section-counter"
      style={{
        backgroundImage: 'url(/img/a2.png)'
      }}
    >
      <Container>
        {/* <Title title="Algunos datos nuestros" /> */}
        <div className="row justify-content-center mb-3 pb-3">
          <div className="col-md-7 text-center heading-section heading-section-white">
            <span className="subheading">RE/MAX SUR III</span>
            <h2 className="mb-4 font-azul-sec">Algunos datos nuestros</h2>
          </div>
        </div>
        <div className="row justify-content-center" ref={countUpRef}>
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-3 d-flex justify-content-center counter-wrap">
                <div className="block-18 text-center">
                  <div className="text">
                    <CountUp
                      separator=" "
                      duration={durantionTotal}
                      end={happies}
                      style={{ fontSize: '30px', fontWeight: '400px' }}
                    />
                    <span>Nuevos propietarios</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-center counter-wrap">
                <div className="block-18 text-center">
                  <div className="text">
                    <CountUp
                      separator=" "
                      duration={durantionTotal}
                      end={totales.sales + totales.rentals}
                      style={{ fontSize: '30px', fontWeight: '400px' }}
                    />
                    <span>Propiedades totales</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-center counter-wrap">
                <div className="block-18 text-center">
                  <div className="text">
                    <CountUp
                      separator=" "
                      duration={durantionTotal}
                      end={totales.sales}
                      style={{ fontSize: '30px', fontWeight: '400px' }}
                    />
                    <span>Propiedades en venta</span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-center counter-wrap">
                <div className="block-18 text-center">
                  <div className="text">
                    <CountUp
                      separator=" "
                      duration={durantionTotal}
                      end={totales.rentals}
                      style={{ fontSize: '30px', fontWeight: '400px' }}
                    />
                    <span>Propiedades en alquiler</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Datos;
