'use client'; // ← Agregar esta línea en la parte superior
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC, useState } from 'react';
import { Container } from 'reactstrap';

import FormData, { formDataEmpty } from '../models/FormData.interface';
import { getEnviarConsulta } from '../services/contacto.services';
import { setLoading, setToast } from '../stores/sharedSlice';
import { useAppDispatch } from '../stores/store';
import Title from './subComponents/title.component';

const Contacto: FC = () => {
  const [formData, setFormData] = useState<FormData>(formDataEmpty);
  const dispatch = useAppDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string | undefined; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name as string]: value
    });
  };

  const handleOperation = (event: any) => {
    setFormData(prevState => ({
      ...prevState,
      operation: event.target.value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // console.log('Form data:', formData);
    try {
      dispatch(setLoading(true));
      await getEnviarConsulta(formData);
      dispatch(setToast({ message: 'Su consulta fue enviada, le responderemos a la brevedad', type: 'SUCCESS' }));
      setFormData(formDataEmpty);
    } catch (error) {
      console.log();
      dispatch(setToast({ message: 'Ocurio un error, vuelva a intentar', type: 'ERROR' }));
    } finally {
      dispatch(setLoading(false));
    }
    // Aquí puedes añadir la lógica para manejar el envío del formulario
  };

  return (
    <section className="ftco-section bg-white" style={{ padding: '3em 0' }} id="section-contacto">
      <Title title="Contanos que buscas y te ayudamos a encontrar tu próxima propiedad" />
      <Container>
        <h3 style={{ display: 'none' }}>Formulario de contacto</h3>
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 1200, margin: '0 auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Nombre"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Correo Electrónico"
                variant="outlined"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <TextField
                label="Teléfono"
                variant="outlined"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={3} sm={6}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel>Quiero</InputLabel>
                <Select name="operation" value={formData.operation} label="Operación" onChange={handleOperation}>
                  <MenuItem value="Comprar">Comprar</MenuItem>
                  <MenuItem value="Vender">Vender</MenuItem>
                  <MenuItem value="Alquiler">Alquilar</MenuItem>
                  <MenuItem value="Indistinto">Indistinto</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                placeholder="Hola, estoy buscando departamento en zona sur para comprar..."
                variant="outlined"
                name="message"
                value={formData.message}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
};

export default Contacto;
