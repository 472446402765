import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Property from '../models/Property';
export interface FiltroSlice {
  operacion: 'TODOS' | 'ALQUILER' | 'VENTA';
  ciudad?: string;
  localidad?: string;
  typesProp?: string;
}
interface PropsInfo {
  data: Property[];
  features: Property[];
  page: number;
  itemPerPage: number;
  totalItems: number;
  loading: boolean;
  filtro: FiltroSlice;
  totals: {
    sales: number;
    rentals: number;
    temporary: number;
  };
}

const initialState: PropsInfo = {
  data: [],
  features: [],
  page: 1,
  loading: true,
  totalItems: 0, // Total de propiedades que trae el filtro
  itemPerPage: 9,
  filtro: {
    operacion: 'TODOS'
  },
  totals: {
    sales: 0,
    rentals: 0,
    temporary: 0
  }
};

export const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setProps: (state, action: PayloadAction<Property[]>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setFiltro: (state, action: PayloadAction<FiltroSlice>) => {
      state.filtro = action.payload;
    },
    setFeatures: (state, action: PayloadAction<Property[]>) => {
      state.features = action.payload;
    },
    setTotals: (state, action: PayloadAction<{ sales: number; rentals: number; temporary: number }>) => {
      state.totals = action.payload;
    },
    resetProperties: state => {
      state.page = 0;
    }
  }
});

// Exporta las acciones para usarlas en los componentes
export const { setProps, setLoading, setPage, setTotalItems, setFiltro, setFeatures, setTotals, resetProperties } =
  propertiesSlice.actions;

// Exporta el reducer para usarlo en el store
export default propertiesSlice.reducer;
