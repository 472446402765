import { FC } from 'react';
import { Button } from 'reactstrap';
import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import FormSearchProperty from '../../models/FormSearchProperty';
import { CitySimple } from '../../models/Location';
import { useAppSelector } from '../../stores/store';

const Buscador: FC<{ setForm: (p: any) => void; form: FormSearchProperty; onSubmit: () => void }> = ({
  setForm,
  form,
  onSubmit
}) => {
  const citiesList = useAppSelector<CitySimple[]>(state => state.cities.data);
  const typesList = useAppSelector<string[]>(state => state.types.data);

  const handleSeleccinarLocalidades = (event: React.ChangeEvent<any>, newValue: CitySimple[]) => {
    // HACK: Donde se guardan las ciudades selccionadas
    setForm((prevState: FormSearchProperty) => ({
      ...prevState,
      cities: newValue
    }));
  };

  const handleOperation = (event: any) => {
    setForm((prevState: FormSearchProperty) => ({
      ...prevState,
      operation: +event.target.value
    }));
  };

  const handleTypes = (event: React.ChangeEvent<any>, newValue: string[]) => {
    setForm((prevState: FormSearchProperty) => ({
      ...prevState,
      typesProp: newValue
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    onSubmit();
  };

  return (
    <Box component="form" sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="operation-type-label">Tipo de Operación</InputLabel>
            <Select
              labelId="operation-type-label"
              value={form.operation}
              onChange={handleOperation}
              label="Tipo de Operación"
            >
              <MenuItem value="1">TODOS</MenuItem>
              <MenuItem value="3">ALQUILER</MenuItem>
              <MenuItem value="2">VENTA</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={typesList}
            value={form.typesProp}
            onChange={handleTypes}
            renderInput={params => <TextField {...params} label="Tipo de propiedad" />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={citiesList}
            groupBy={(option: CitySimple) => option.city}
            getOptionLabel={(option: CitySimple) => option.locality}
            value={form.cities}
            onChange={handleSeleccinarLocalidades}
            renderInput={params => <TextField {...params} label="Lugar/Zona" />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e: any) => handleSubmit(e)}
            className="background-azul"
            sx={{ mt: 2 }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Buscador;
