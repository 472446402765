import FormData from '../models/FormData.interface';
import axiosClient from './axiosClient';

export const getEnviarConsulta = async (formData: FormData): Promise<boolean> => {
  try {
    await axiosClient.post<boolean>(`/submit-query`, formData);
    return true;
  } catch (error: any) {
    return Promise.reject(error);
  }
};
